import './about.css';
import {PrimaryInfo, SecondaryInfo} from "../util/util";

export function About() {
    return (
        <div>
            <PrimaryInfo text={"🚧About Music Metrics🚧"}/>
            <SecondaryInfo text={"Page coming soon..."}/>
        </div>
    )
}